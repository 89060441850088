import { useState } from "react"
import axios from "axios"
import { ThreeDots } from "react-loader-spinner"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"

const ClientSearchById = ({ city }) => {
	const [clientId, setClientId] = useState("")
	const [client, setClient] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const accessString = localStorage.getItem("JWT")
	const pets =
		client && (city === "milton" ? client.Pets : client.PetCambridges)

	const handleSearch = async (e) => {
		e.preventDefault()
		setLoading(true)
		setError("")
		setClient(null)
		try {
			await axios
				.get(`/api/client_by_id/${city}/${clientId}`, {
					headers: { jwt: `${accessString}` },
				})
				.then((res) => {
					if (res.data) {
						setTimeout(() => {
							setClient(res.data)
							setLoading(false)
						}, 1000)
					} else {
						setTimeout(() => {
							setError("That client Id does not exist")
							setLoading(false)
						}, 1000)
					}
				})
				.catch((err) => console.log(err))
		} catch (err) {
			setError("Error searching for client")
			console.error(err)
		}
	}

	return (
		<div className="clients-list-table">
			<h3 className="clientsList-mainTitle" id="clientsList">
				<span className="capitalize">{city}</span> Clients{" "}
				<i className="fas fa-user"></i>
			</h3>
			<form
				onSubmit={handleSearch}
				className="flex-row w-full p-2 mx-auto text-center border border-slate-500 sm:w-1/2 d-flex align-items-center"
			>
				<label
					className="mx-2 h5 text-slate-500 searchByClientLabel"
					htmlFor=""
				>
					Search by Client Id
				</label>
				<input
					type="text"
					value={clientId}
					onChange={(e) => setClientId(e.target.value)}
					placeholder="Enter client ID"
					className="flex-1 p-2 border rounded"
				/>
				<button
					type="submit"
					className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
				>
					Search
				</button>
			</form>

			{loading ? (
				<div className="flex justify-center">
					<ThreeDots
						height="40"
						width="40"
						radius="9"
						color="#4fa94d"
						ariaLabel="three-dots-loading"
						wrapperStyle={{}}
						wrapperClassName=""
						visible={true}
					/>
				</div>
			) : (
				client && (
					<div className="w-[99%] mx-auto">
						<Table className="mt-2 border border-gray">
							<thead className="bg-cyan-300">
								<tr>
									<th className="px-4 py-2 border-b">Client #</th>
									<th className="px-4 py-2 border-b">First Name</th>
									<th className="px-4 py-2 border-b">Last Name</th>
									<th className="px-4 py-2 border-b">Pets</th>
									<th className="px-4 py-2 border-b">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr key={client.id} className="border-b bg-cyan-50 border-gray">
									<td className="px-4 py-2">{client.id}</td>
									<td className="px-4 py-2">{client.firstName}</td>
									<td className="px-4 py-2">{client.lastName}</td>
									<td className="px-4 py-2">
										{pets.map((pet, index) => (
											<div key={pet.id} className="mb-1">
												<p className="text-gray">
													<span className="font-bold">{pet.name}</span> --{" "}
													{pet.breed}
												</p>
												{city === "milton" &&
													pets.length > 1 &&
													index !== pets.length - 1 && (
														<hr className="my-1 border-gray" />
													)}
											</div>
										))}
									</td>
									<td className="px-4 py-2">
										<Link
											to={`/clients/${city}/${client.id}`}
											className="inline-block w-full px-4 py-2 text-center text-white bg-blue-800 rounded-md"
										>
											More Info...
										</Link>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
				)
			)}

			{error && <p className="text-red-500 text-center py-4">{error}</p>}
		</div>
	)
}

export default ClientSearchById
