import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import MainCalendar from "../../components/Calendars/MainCalendar"
import SearchClients from "../../components/SearchClients"
import { FaSquareArrowUpRight } from "react-icons/fa6"
import { CgSandClock } from "react-icons/cg"
import { Table, Button, Form, Input } from "reactstrap"
import "./style.scss"
import WaitListAdmin from "../../components/WaitListAdmin"
import ModalSearchResult from "./ModalSearchAll"
import ModalToAddClients from "./ModalToAddClients"
import ModalOptionAddClientsDisabled from "./ModalOptionAddClientsDisabled"
import { Helmet } from "react-helmet"
import moment from "moment"
import { SiAdblock } from "react-icons/si"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import ModalGeneralPurpose from "../../components/Modals/ModalGeneralPurpose"
import DogBreedSearch from "../../components/DogBreedSearch"
import { BiSolidDog } from "react-icons/bi"
import ClientSearchById from "../../components/ClientSearchById"

const TODAY_DATE = moment(new Date()).format("YYYY-MM-DD")

const Index = () => {
	const { setCommissions } = useContext(RegistrationContext)
	const [dates, setDates] = useState({
		paola: new Date(),
		paola_cambridge: new Date(),
		diana: new Date(),
		frank: new Date(),
		baths: new Date(),
		placeholder: new Date(),
	})
	const [dogsBreeds, setDogsBreeds] = useState([])
	const [breedsLookupModal, setBreedsLookupModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState(false)
	const [toggleSearchClientByPhoneForm, setToggleSearchClientByPhoneForm] =
		useState(false)
	const [modalToAddClients, setModalToAddClients] = useState(false)
	const [modalSearchResult, setModalSearchResult] = useState(false)
	const [showDaycare, setShowDaycare] = useState(false)
	const [searchAppointmentsForm, setSearchAppointmentsForm] = useState(false)
	const [searchAppointmentField, setSearchAppointmentField] = useState("")
	const [searchResultsDetails, setSearchResultsDetails] = useState([])
	const [jobType, setJobType] = useState("")
	const [toggleClientsTable, setToggleClientsTable] = useState(false)
	const [toggleCalendars, setToggleCalendars] = useState(true)
	const [waitList, setWaitList] = useState(false)
	const [client_id_field, setClient_id_field] = useState("")
	const [clientIdResult, setClientIdResult] = useState("")
	const [city, setCity] = useState("")
	const [date, setDate] = useState(new Date())
	const [authorized, setAuthorized] = useState(false)
	const [triggerFetchEvents, setTriggerFetchEvents] = useState(false)
	const accessString = localStorage.getItem("JWT")

	const handleNavigation = (direction) => {
		setDates((prevDates) => {
			const updatedDates = {}
			const increment = direction === "next" ? 1 : -1

			Object.keys(prevDates).forEach((calendarId) => {
				updatedDates[calendarId] = new Date(
					prevDates[calendarId].getFullYear(),
					prevDates[calendarId].getMonth(),
					prevDates[calendarId].getDate() + increment
				)
			})

			return updatedDates
		})
	}

	const handleCalendarNavigate = (calendarId, newDate) => {
		setDates((prevDates) => ({
			...prevDates,
			[calendarId]: newDate,
		}))
	}

	const fetchAuth = async () => {
		await axios
			.get("/auth/employees_profile", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => setJobType(res.data.jobType))
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		if (
			jobType === "admin" ||
			jobType === "receptionist" ||
			jobType === "receptionistCalendars"
		) {
			setAuthorized(true)
		}
	}, [jobType])

	useEffect(() => {
		const accessString = localStorage.getItem("JWT")

		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual"
		}
		window.scrollTo(0, 0)

		if (accessString == null) {
			setIsLoading(false)
			setError(true)
		} else {
			try {
				fetchAuth()
				setIsLoading(false)
				setError(false)
				setCity("milton")
				localStorage.setItem("CITY", "milton")
				getAllCommissions()
			} catch (error) {
				console.error(error.response)
				setError(true)
			}
		}
	}, [])

	const getAllCommissions = async () => {
		await axios
			.get("/auth/salaries/hourly/commission/" + TODAY_DATE)
			.then((res) => {
				setCommissions(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleToggleClientsSearchById = () => {
		setToggleClientsTable(!toggleClientsTable)
		setToggleCalendars(false)
		setWaitList(false)
		setShowDaycare(false)
	}

	const handleToggleCalendars = () => {
		setToggleCalendars(!toggleCalendars)
		setWaitList(false)
		setToggleClientsTable(false)
		setShowDaycare(false)
	}
	const handleToggleWaitlist = () => {
		setWaitList(!waitList)
		setToggleCalendars(false)
		setToggleClientsTable(false)
		setShowDaycare(false)
		setToggleSearchClientByPhoneForm(false)
		setSearchAppointmentsForm(false)
	}

	const toggleFormToSearchByPhone = () => {
		setToggleSearchClientByPhoneForm(!toggleSearchClientByPhoneForm)
		setWaitList(false)
		setShowDaycare(false)
		if (searchAppointmentsForm) {
			setSearchAppointmentsForm(false)
		} else if (toggleClientsTable) {
			setToggleClientsTable(false)
			setToggleCalendars(true)
		}
	}

	const getAllDogBreeds = async () => {
		const accessString = localStorage.getItem("JWT")

		await axios
			.get("/api/dogs/breeds", {
				headers: { jwt: `${accessString}` },
			})
			.then((res) => {
				setDogsBreeds(res.data)
			})
			.catch((err) => console.log(err))
	}

	const dogBreedsHandler = async () => {
		if (dogsBreeds.length === 0) await getAllDogBreeds()
		setBreedsLookupModal(!breedsLookupModal)
	}

	const toggleSearchAppointments = () => {
		setSearchAppointmentsForm(!searchAppointmentsForm)
		setWaitList(false)
		setShowDaycare(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const toggleDaycare = () => {
		setShowDaycare(!showDaycare)
		setSearchAppointmentsForm(false)
		setToggleCalendars(false)
		setToggleClientsTable(false)
		setWaitList(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const toggleModalSearchResults = () => {
		setModalSearchResult(!modalSearchResult)
	}

	const handleSearchAppointmentsSubmit = async (e) => {
		e !== undefined && e.preventDefault()

		await axios
			.get(`/schedule/calendar/search/all/${searchAppointmentField}`, {
				title: searchAppointmentField,
			})
			.then((res) => {
				const filteredEvents = res.data.filter(
					(event) =>
						event.city === "milton" && !event.deletedAt && !event.isBeingBooked
				)
				toggleModalSearchResults()
				setSearchResultsDetails(filteredEvents)
			})
			.catch((err) => console.log(err))
	}

	const showEventOnCalaendar = (eventId) => {
		console.log(eventId)
	}

	if (isLoading) {
		return (
			<div
				style={{
					marginTop: "120px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Loading...if it does not respond, refresh this page
			</div>
		)
	}

	if (error) {
		console.log(error)
		return (
			<div
				style={{
					marginLeft: "10%",
					marginTop: "120px",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				...Problem fetching user data. Please login again
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}

	if (!authorized) {
		return (
			<div className="mt-24 text-3xl text-center">
				<SiAdblock size={40} className="inline-block text-red-500" /> <br />{" "}
				Unauthorized
			</div>
		)
	}

	if (
		jobType === "receptionist" ||
		jobType === "receptionistCalendars" ||
		jobType === "admin"
	) {
		const searchResultsById = clientIdResult && (
			<tr key={clientIdResult.id} className="border-b bg-cyan-50 border-gray">
				<td className="px-4 py-2">{clientIdResult.id}</td>
				<td className="px-4 py-2">{clientIdResult.firstName}</td>
				<td className="px-4 py-2">{clientIdResult.lastName}</td>
				<td className="px-4 py-2">
					{clientIdResult.Pets.map((pet) => (
						<div key={pet.id} className="mb-1">
							<p className="text-gray">
								<span className="font-bold">{pet.name}</span> -- {pet.breed}
							</p>
							{clientIdResult.Pets.length > 1 && (
								<hr className="my-1 border-gray" />
							)}
						</div>
					))}
				</td>
				<td className="px-4 py-2">
					<Link
						to={`/clients/${city}/${clientIdResult.id}`}
						className="inline-block w-full px-4 py-2 text-center text-white bg-blue-800 rounded-md"
					>
						More Info...
					</Link>
				</td>
			</tr>
		)

		return (
			<div className="pt-16 mt-12 bg-pink-50">
				<Helmet>
					<title>Dashboard</title>
				</Helmet>
				<div className="">
					<div className="row justify-content-center control-panel-container">
						<h1 className="text-center flex sm:text-xl px-3 text-[20px] flex-wrap">
							<span className="px-1 mr-2 text-white border-2 border-pink-500 bg-pink">
								Milton
							</span>
							Main Desk Control Panel
							<Link
								className="inline-block w-full sm:inline sm:w-72"
								to={"/auth/reception/cambridge"}
								color="dark"
							>
								<button className="px-2 py-1 ml-2 text-sm text-white bg-purple-600 rounded-sm hover:bg-purple-500 sm:text-xl">
									<FaSquareArrowUpRight className="inline-block" /> Switch to
									Cambridge
								</button>
							</Link>
						</h1>
						<div className="col-md-12 ">
							<h1 className="controlPanelHeadingMobile">Control Panel</h1>
							<div className="flex flex-wrap justify-center gap-2 mt-2">
								<button
									onClick={handleToggleWaitlist}
									className="px-2 border-2 rounded-sm bg-cyan-300 hover:bg-cyan-200 border-cyan-700"
								>
									Waitlist <CgSandClock className="inline-block" />
								</button>
								<button
									onClick={handleToggleClientsSearchById}
									className="px-2 py-1 bg-yellow-100 border-2 border-yellow-700 rounded-sm hover:bg-yellow-50"
								>
									Search Client By Id <i className="fas fa-users"></i>
								</button>

								{/* See calendars */}
								{!toggleCalendars ? (
									<button
										className="px-2 py-1 text-white rounded-sm bg-pink hover:bg-pink-400"
										onClick={handleToggleCalendars}
									>
										Schedule <i className="fas fa-clipboard-list"></i>
									</button>
								) : null}

								{/* Search client by any parameter */}
								<button
									className="px-2 py-1 bg-green-300 border-2 border-green-700 rounded-sm hover:bg-green-200"
									onClick={toggleFormToSearchByPhone}
								>
									Search Clients <i className="fas fa-search"></i>
								</button>
								<button
									color="dark"
									className="px-2 py-1 text-white bg-blue-800 rounded-sm hover:bg-slate-500"
									onClick={toggleSearchAppointments}
								>
									<i className="fas fa-search"></i> All Appointments Search
								</button>
								<button
									onClick={toggleDaycare}
									className="px-2 py-1 text-white bg-yellow-600 border-2 border-yellow-900 rounded-sm"
								>
									Daycare
								</button>
								<button
									onClick={dogBreedsHandler}
									className="px-2 py-1 text-white bg-purple-500 border-2 border-purple-900 rounded-sm"
								>
									<BiSolidDog className="inline mr-2" />
									Breed Lookup
								</button>
							</div>
						</div>
					</div>
					<hr />

					{showDaycare && (
						<div
							id="DaycareCalendar"
							className={"col-xl-12 col-md-12 col-xs-12"}
						>
							<MainCalendar
								isFromEditClients={false}
								triggerFetchEvents={triggerFetchEvents}
								setTriggerFetchEvents={setTriggerFetchEvents}
								date={date}
								isBather={false}
								city="milton"
								groomer={"daycare"}
								step={15}
								timeSlots={4}
								min={new Date(2019, 10, 0, 7, 0, 0)}
								max={new Date(2019, 10, 0, 19, 0, 0)}
								standardControls
							/>
						</div>
					)}

					{searchAppointmentsForm ? (
						<div className="mx-auto border shadow-lg w-50">
							<form onSubmit={handleSearchAppointmentsSubmit}>
								<input
									placeholder="Search by: phone, pet name, breed."
									onChange={(e) => setSearchAppointmentField(e.target.value)}
									required
									className="input-search-appointments"
									type="text"
									name="searchAppointmentField"
								/>
								<div className="text-center">
									<button className="m-2 btn btn-info" type="submit">
										Search All Calendars <i className="fas fa-search"></i>
									</button>
								</div>
							</form>
						</div>
					) : null}
					{localStorage.getItem("USERNAME") === "paoyepes" ||
					localStorage.getItem("USERNAME") === "alexyepes" ? (
						//  MODAL TO ADD CLIENTS
						<ModalToAddClients
							city={city}
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					) : (
						// MODAL TO SHOW OPTION DISABLED CLIENTS
						<ModalOptionAddClientsDisabled
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					)}
				</div>

				{/* Forms to search clients by name, last name, first name, and pet name */}
				{toggleSearchClientByPhoneForm ? <SearchClients /> : null}

				{toggleClientsTable ? <ClientSearchById city={"milton"} /> : null}

				{waitList ? <WaitListAdmin /> : null}

				{toggleCalendars ? (
					<div>
						<div className="flex-row justify-between hidden gap-2 sm:flex">
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("back")}
							>
								&#x2190; All Calendars Back
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() =>
									setDates({
										paola: new Date(),
										paola_cambridge: new Date(),
										claudia: new Date(),
										diana: new Date(),
										frank: new Date(),
										baths: new Date(),
										mercedes: new Date(),
									})
								}
							>
								RESET All Calendars
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("next")}
							>
								All Calendars Next &rarr;
							</button>
						</div>

						<div className="row calendars-group">
							{/* PAOLA MILTON */}
							<div
								id="AdminCalendar"
								className={
									localStorage.getItem("USERNAME") === "paoyepes"
										? "col-xl-6 col-md-6 col-xs-12 cal-all"
										: "col-xl-4 col-md-4 col-xs-12 cal-all"
								}
							>
								<MainCalendar
									isFromEditClients={false}
									triggerFetchEvents={triggerFetchEvents}
									setTriggerFetchEvents={setTriggerFetchEvents}
									date={dates.paola}
									onNavigate={(newDate) =>
										handleCalendarNavigate("paola", newDate)
									}
									isBather={false}
									city="milton"
									groomer={"paola"}
									step={15}
									timeSlots={4}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 18, 0, 0)}
								/>
							</div>
							{/* PAOLA CAMBRIDGE */}
							{localStorage.getItem("USERNAME") === "paoyepes" && (
								<div
									id="AdminCalendar"
									className="col-xl-6 col-md-6 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.paola_cambridge}
										onNavigate={(newDate) =>
											handleCalendarNavigate("paola_cambridge", newDate)
										}
										isBather={false}
										city="cambridge"
										groomer={"paola"}
										step={15}
										timeSlots={4}
										min={new Date(2019, 10, 0, 8, 30, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>
							)}

							<>
								{/* DIANA MILTON */}
								<div
									id="Emp2Calendar"
									className={
										localStorage.getItem("USERNAME") === "paoyepes"
											? "col-xl-6 col-md-6 col-xs-12 cal-all"
											: "col-xl-4 col-md-4 col-xs-12 cal-all"
									}
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.diana}
										onNavigate={(newDate) =>
											handleCalendarNavigate("diana", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"diana"}
										step={30}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>

								{/* BATHS MILTON */}
								<div
									id="Emp4Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.baths}
										onNavigate={(newDate) =>
											handleCalendarNavigate("baths", newDate)
										}
										city="milton"
										isBather={true}
										groomer={"bather"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 18, 0, 0)}
									/>
								</div>
								{/* FRANK MILTON */}
								<div
									id="Emp3Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.frank}
										onNavigate={(newDate) =>
											handleCalendarNavigate("frank", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"frank"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>
								{/* NEW GROOMER MILTON */}
								{/* <div
									id="Emp6Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.placeholder}
										onNavigate={(newDate) =>
											handleCalendarNavigate("placeholder", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"placeholder"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div> */}
							</>
						</div>
					</div>
				) : null}

				<ModalSearchResult
					modalSearchResults={modalSearchResult}
					toggleModalSearchResults={toggleModalSearchResults}
					searchResultsDetails={searchResultsDetails}
					searchAppointmentField={searchAppointmentField}
					showEventOnCalaendar={showEventOnCalaendar}
					handleSearchAppointmentsSubmit={() =>
						handleSearchAppointmentsSubmit()
					}
					setSearchResultsDetails={setSearchResultsDetails}
					triggerFetchEvents={triggerFetchEvents}
					setTriggerFetchEvents={setTriggerFetchEvents}
				/>

				<ModalGeneralPurpose
					modalSize="md"
					isModalOpen={breedsLookupModal}
					toggleModal={() => setBreedsLookupModal(!breedsLookupModal)}
					modalProps={{
						breeds: dogsBreeds,
					}}
					modalContent={DogBreedSearch}
				/>
			</div>
		)
	} else {
		return (
			<div
				style={{
					marginTop: "100px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				You don't have permission to access this page
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}
}

export default Index
