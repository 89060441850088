import { useEffect } from "react"
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Input,
	Label,
} from "reactstrap"
import LoadPage from "../../LoadingPage"
import moment from "moment"
import Select from "react-select"

export default function Index({
	modalToAddEvent,
	setModalToAddEvent,
	props,
	onSubmitModal,
	appointment,
	setAppointment,
	optionsAdmin,
	optionsTrainee,
	options,
	slotEvent,
	setTitle,
	title,
	titleInputEmpty,
	setTitleInputEmpty,
	setBreedName,
	breedName,
	setBreedInputEmpty,
	breedInputEmpty,
	loadingAxiosReq,
	errorOnModalCalendar,
	appointmentDetailsObj,
	availableSpotsDate,
	numOfSpots,
	setNumOfSpots,
	addDaycareHandler,
	extraDetails,
	isExpress,
	setIsExpress,
	isPrebook,
	setIsPrebook,
	selectedTime,
	setSelectedTime,
}) {
	const morningStartTimes = [
		"7:00 AM",
		"7:30 AM",
		"8:00 AM",
		"8:30 AM",
		"9:00 AM",
		"9:30 AM",
		"10:00 AM",
		"10:30 AM",
		"11:00 AM",
		"11:30 AM",
		"12:00 PM",
		"12:30 PM",
		"1:00 PM",
	]

	const afternoonStartTimes = [
		"1:30 PM",
		"2:00 PM",
		"2:30 PM",
		"3:00 PM",
		"3:30 PM",
		"4:00 PM",
		"4:30 PM",
	]

	const allEndTimes = [
		"7:30 AM",
		"8:00 AM",
		"8:30 AM",
		"9:00 AM",
		"9:30 AM",
		"10:00 AM",
		"10:30 AM",
		"11:00 AM",
		"11:30 AM",
		"12:00 PM",
		"12:30 PM",
		"1:00 PM",
		"1:30 PM",
		"2:00 PM",
		"2:30 PM",
		"3:00 PM",
		"3:30 PM",
		"4:00 PM",
		"4:30 PM",
		"5:00 PM",
		"5:30 PM",
		"6:00 PM",
		"6:30 PM",
		"7:00 PM",
	]

	useEffect(() => {
		if (appointmentDetailsObj) {
			setTitle(appointmentDetailsObj.title)
			setTitleInputEmpty(false)
		}
	}, [appointmentDetailsObj])

	const pasteAppointmentDetailsHandler = (e) => {
		e.preventDefault()
		setTitle(appointmentDetailsObj.title)
		setTitleInputEmpty(false)
		setBreedName(appointmentDetailsObj.breedName)
		setBreedInputEmpty(false)
		setIsExpress(appointmentDetailsObj.isExpress)
		setIsPrebook(appointmentDetailsObj.isPrebook)
		setAppointment({
			value: appointmentDetailsObj.appointment,
			label: appointmentDetailsObj.appointment,
		})
	}

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			alert("Copied to clipboard!")
		})
	}

	const handleExpressChange = (event) => {
		setIsExpress(event.target.checked)
	}

	const handlePrebookChange = (event) => {
		setIsPrebook(event.target.checked)
	}

	return (
		<Modal
			modalClassName="modal-cambridge-calendar"
			isOpen={modalToAddEvent}
			toggle={() => {
				setModalToAddEvent(!modalToAddEvent)
				if (!modalToAddEvent) {
					setAppointment("")
					setTitle("")
					setBreedName("")
				}
			}}
		>
			<ModalHeader
				toggle={() => {
					if (!modalToAddEvent) {
						setAppointment("")
					}
					setModalToAddEvent(!modalToAddEvent)
				}}
			>
				Set The event type on the Calendar for {props.groomer.toUpperCase()}
				{extraDetails && (
					<>
						<div className="overflow-x-auto mt-[10px]">
							<table className="min-w-full bg-white border rounded-xl border-[#ced4da]">
								<tbody>
									<tr
										className="cursor-copy"
										onClick={() => {
											copyToClipboard(
												`${extraDetails.id} - ${extraDetails.phone}`
											)
										}}
									>
										<td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
											Id - Primary phone number
										</td>
										<td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
											<b>
												{extraDetails.id} - {extraDetails.phone}
											</b>
										</td>
									</tr>

									{extraDetails.pets.length > 0 && (
										<tr
											className={`${
												extraDetails.pets.length === 1 && "cursor-copy"
											}`}
											onClick={() => {
												if (extraDetails.pets.length === 1) {
													copyToClipboard(
														extraDetails.pets &&
															extraDetails.pets
																.map((pet) => `${pet.name} (${pet.breed})`)
																.join(", ")
													)
												}
											}}
										>
											<td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
												Pets
											</td>
											<td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
												<b>
													{extraDetails.pets &&
														extraDetails.pets
															.map((pet) => `${pet.name} (${pet.breed})`)
															.join(", ")}
												</b>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}
				<br />
				{!extraDetails && props.groomer !== "daycare" && (
					<>
						<hr />
						<span className="flex flex-row items-center">
							<label htmlFor="expressService">
								Service Type:{" "}
								<span
									className={`ml-2 ${
										!isExpress ? "text-red-500" : "text-green-500"
									} `}
								>{`${isExpress ? "Express" : "Regular"}`}</span>
							</label>
							<input
								type="checkbox"
								id="expressService"
								checked={isExpress}
								onChange={handleExpressChange}
								className="ml-2 mb-2 h-6 w-6"
							/>
						</span>
						<span className="flex flex-row items-center">
							<label htmlFor="prebookService">
								Prebook:{" "}
								<span
									className={`ml-2 ${
										!isPrebook ? "text-red-500" : "text-green-500"
									} `}
								>{`${isPrebook ? "Yes" : "No"}`}</span>
							</label>
							<input
								type="checkbox"
								id="prebookService"
								checked={isPrebook}
								onChange={handlePrebookChange}
								className="ml-2 mb-2 h-6 w-6"
							/>
						</span>
					</>
				)}
			</ModalHeader>
			<ModalBody>
				{availableSpotsDate ? (
					<form onSubmit={addDaycareHandler}>
						No Daycare is available for this day, do you want to add spots?
						<br />
						<input
							value={numOfSpots}
							className="w-full p-2 my-2 border rounded-sm"
							placeholder="Number of spots"
							type="number"
							min={0}
							onChange={(e) => setNumOfSpots(e.target.value)}
						/>
						<button className="w-full p-2 bg-orange-300 rounded-sm">
							Enable Daycare
						</button>
					</form>
				) : (
					<>
						<Form onSubmit={onSubmitModal}>
							{appointmentDetailsObj && (
								<button
									onClick={pasteAppointmentDetailsHandler}
									className="w-full p-2 my-4 text-white bg-purple-600 rounded-md"
								>
									Paste appointment
								</button>
							)}
							<FormGroup>
								{props.groomer === "daycare" ? (
									<>
										<div className="mb-4">
											<label
												htmlFor="startTime"
												className="block mb-2 font-bold"
											>
												Daycare Session Start Time
											</label>
											<select
												id="startTime"
												value={selectedTime?.start || ""}
												onChange={(e) => {
													setSelectedTime({
														...selectedTime,
														start: e.target.value,
													})
												}}
												className="w-full p-2 border rounded"
												required
											>
												<option value="">Select start time</option>
												{moment(slotEvent.start).hour() < 13
													? morningStartTimes.map((time) => (
															<option key={time} value={time}>
																{time}
															</option>
													  ))
													: afternoonStartTimes.map((time) => (
															<option key={time} value={time}>
																{time}
															</option>
													  ))}
											</select>
										</div>
										<div className="mb-4">
											<label htmlFor="endTime" className="block mb-2 font-bold">
												Daycare Session End Time
											</label>
											<select
												id="endTime"
												value={selectedTime?.end || ""}
												onChange={(e) => {
													setSelectedTime({
														...selectedTime,
														end: e.target.value,
													})
												}}
												className="w-full p-2 border rounded"
												required
											>
												<option value="">Select end time</option>
												{allEndTimes.map((time) => (
													<option key={time} value={time}>
														{time}
													</option>
												))}
											</select>
										</div>
										<span className="inline-block my-2 font-bold">
											{selectedTime?.start && selectedTime?.end
												? `Daycare Session: ${selectedTime.start} - ${selectedTime.end}`
												: "Please select start and end times above"}
										</span>
									</>
								) : (
									<Select
										value={appointment}
										name="form-field-name"
										options={
											props.groomer === "paola"
												? optionsAdmin
												: props.isTrainee
												? optionsTrainee
												: options
										}
										placeholder="Select one of the following:"
										isSearchable={false}
										onChange={(value) => setAppointment(value)}
									/>
								)}

								<br />
								{(appointment.value !== "online" ||
									appointment.value !== "onlineBath" ||
									appointment.value !== "onlineCat" ||
									appointment.value !== "staffOnly") &&
								(slotEvent.appointment === "schedule" ||
									slotEvent.appointment === "staffOnly") ? (
									<>
										<Label>Custom Staff Event</Label>
										<Input
											type="text"
											name="title"
											id="title"
											value={title}
											placeholder="Type your details or leave it empty"
											onChange={(e) => {
												setTitle(e.target.value)
												if (e.target.value) {
													setTitleInputEmpty(false)
												}
											}}
											onKeyUp={(e) => {
												if (e.keyCode === 8) {
													setTitle(e.target.value)
												}
											}}
											className={titleInputEmpty ? "borderError" : ""}
										/>
										<br />
									</>
								) : appointment.value === "schedule" ||
								  appointment.value === "staffOnly" ? (
									<>
										<Label>Custom Staff Event</Label>
										<Input
											type="text"
											name="title"
											id="title"
											value={title}
											placeholder="(Optional) Type your details or leave it empty"
											onChange={(e) => {
												setTitle(e.target.value)
												if (e.target.value) {
													setTitleInputEmpty(false)
												}
											}}
											onKeyUp={(e) => {
												if (e.keyCode === 8) {
													setTitle(e.target.value)
												}
											}}
											className={titleInputEmpty ? "borderError" : ""}
										/>
										<br />
									</>
								) : (
									<>
										<Input
											type="text"
											name="title"
											id="title"
											value={title}
											placeholder="*Please enter Phone and other details"
											onChange={(e) => {
												setTitle(e.target.value)
												if (e.target.value || !slotEvent.title) {
													setTitleInputEmpty(false)
												}
											}}
											onKeyUp={(e) => {
												if (e.keyCode === 8) {
													setTitle(e.target.value)
												}
											}}
											className={titleInputEmpty ? "borderError" : ""}
										/>
										<br />
										<Input
											type="text"
											name="breedName"
											id="breedName"
											value={breedName}
											placeholder="* Enter Name and Breed"
											onKeyUp={(e) => {
												if (e.keyCode === 8) {
													setBreedName(e.target.value)
												}
											}}
											onChange={(e) => {
												setBreedName(e.target.value)
												if (e.target.value || !slotEvent.breedName) {
													setBreedInputEmpty(false)
												}
											}}
											className={breedInputEmpty ? "borderError" : ""}
										/>
									</>
								)}

								<p className="time-on-modal">
									Starts:{" "}
									{moment(slotEvent.start).format("dddd, MMMM Do YYYY, h:mm a")}
								</p>
								<p className="time-on-modal">
									Ends:{" "}
									{moment(slotEvent.end).format("dddd, MMMM Do YYYY, h:mm a")}
								</p>

								<p
									style={{
										color: "red",
										textAlign: "center",
										marginTop: "10px",
									}}
								>
									{errorOnModalCalendar}
								</p>
								{loadingAxiosReq ? (
									<LoadPage />
								) : (
									<Button color="info" style={{ marginTop: "1rem" }} block>
										Submit Event
									</Button>
								)}
							</FormGroup>
						</Form>
					</>
				)}
			</ModalBody>
		</Modal>
	)
}
